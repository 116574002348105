import { Component, OnInit, Inject } from '@angular/core'
import { ApiService } from '../api.service';
import {ActivatedRoute} from '@angular/router'; 
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


export interface DialogData {
  barcode: string;
}

@Component({
  selector: 'app-add-items',
  templateUrl: './add-items.component.html',
  styleUrls: ['./add-items.component.css']
})
export class AddItemsComponent implements OnInit {
  displayedColumns: string[] = ['item_barcode','item_code','item_name','ware_barcode','warehouse_name'];
  dataSource:any = [];
  shipmentId: any;
  item: any;
  constructor(private api: ApiService , private route:ActivatedRoute, public dialog: MatDialog) { 
    
  }

  ngOnInit(): void {
    // First get the product id from the current route.
    const routeParams = this.route.snapshot.paramMap;
    this.shipmentId = String(routeParams.get('shipmentId'));
    this.api.getItems(this.shipmentId).subscribe(data=>{
      if(data['status']=='success'){
        this.dataSource = data['items'];
      }
    });
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(ItemDialog, {
      width: '250px',
      data: {barcode: ''}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.item = result;
      this.checkItemByBarCode(result);
    });
  }
  checkItemByBarCode(barcode): void {
    this.api.checkItemByBarCode(barcode).subscribe(data=>{
      console.log(data)
    });
  }

}
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html',
})
export class ItemDialog {

  constructor(
    public dialogRef: MatDialogRef<ItemDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
