import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule } from '@angular/material/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';
import {BidiModule} from '@angular/cdk/bidi';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';

import { ShipmentComponent } from './shipment/shipment.component';
import { AddShipmentComponent } from './add-shipment/add-shipment.component';
import { AddItemsComponent } from './add-items/add-items.component';
import { ItemDialog } from './add-items/add-items.component';
import { ItemRecievingComponent } from './item-recieving/item-recieving.component';
import { ShipmentDialog } from './item-recieving/item-recieving.component';
import { SpinnerDialog } from './item-recieving/item-recieving.component';
import { ItemStoringComponent } from './item-storing/item-storing.component';
import { SpinnerStoringDialog } from './item-storing/item-storing.component';
import { ShipmentStoringDialog } from './item-storing/item-storing.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    ShipmentComponent,
    AddShipmentComponent,
    AddItemsComponent,
    ItemDialog,
    ItemRecievingComponent,
    ShipmentDialog,
    SpinnerDialog,
    ItemStoringComponent,
    SpinnerStoringDialog,
    ShipmentStoringDialog,
    DashboardComponent,
    LoginComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: TranslationLoaderFactory, deps: [HttpClient]}
    }),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatGridListModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDialogModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatToolbarModule, 
    MatSelectModule,
    MatMenuModule,
    BidiModule,
    NgbModule
  ],
  providers: [ConfirmationDialogService],
  bootstrap: [AppComponent],
})
export class AppModule { }
