import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from './auth/models/user';
import { AuthenticationService } from './auth/service/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'electshipment';
  browserlang = '';
  user: User;
  langs = ['en', 'ar'];
  direction = '';
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService
  ) {
    this.authenticationService.user.subscribe(x => this.user = x);
    this.browserlang = this.translateService.getBrowserLang();
    if (this.langs.indexOf(this.browserlang) > -1) {
      this.translateService.setDefaultLang(this.browserlang);
      if (this.browserlang == 'en') {
        this.direction = 'ltr';
      }
      else
      {
        this.direction = 'rtl';
      }
    } else {
      this.translateService.setDefaultLang('en');
      this.direction = 'ltr';
    }
  }
  logout() {
    this.authenticationService.logout();
  }
  useLanguage(language: string): void {
    this.translateService.use(language);
    this.browserlang = language;
    if (this.browserlang == 'en') {
      this.direction = 'ltr';
    }
    else
    {
      this.direction = 'rtl';
    }
  }
}
