<div class="example-button-row">
    <div class="example-flex-container">
        <div class="example-button-container" routerLink="/shipments"  style="width: 23%;">
            <button mat-fab color="primary" aria-label="Example icon button with a delete icon" style="width:100%">
                <mat-icon> local_shipping</mat-icon>
            </button>
            <br/>
            <mat-label>{{ 'menu.shipment' | translate }}</mat-label>
        </div>
        <div class="example-button-container" routerLink="/recieving" style="width: 23%;">
            <button mat-fab color="accent" aria-label="Example icon button with a bookmark icon" style="width:100%">
                <mat-icon>pan_tool</mat-icon>
            </button>
            <br/>
            <mat-label>{{ 'menu.recieving' | translate }}</mat-label>
        </div>
        <div class="example-button-container" routerLink="/storing"  style="width: 23%;">
            <button mat-fab color="warn" aria-label="Example icon button with a home icon" style="width:100%">
                <mat-icon>commute</mat-icon>
            </button>
            <br/>
            <mat-label>{{ 'menu.storing' | translate }}</mat-label>
        </div>
    </div>
</div>