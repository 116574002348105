<div *ngIf="shipmentNo" id="recieving">
    <h1>{{'label.warehouse_storing' | translate}} ({{ shipmentNo }})</h1>
    <mat-divider></mat-divider>
    <mat-grid-list cols="1" rowHeight="8:1">
        <mat-grid-tile *ngIf="level==1">
            <mat-label>
                <h1 id="barcode">{{'label.itembarcode' | translate}}</h1>
            </mat-label>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>{{'label.barcode' | translate}}</mat-label>
                <input matInput #txtVal (keydown.enter)="checkItemByBarCode(txtVal.value)"  [(ngModel)]="itembarcode">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="level==1">
            <mat-label>
                <h1>{{'label.warehouse' | translate}} ({{ warehouseName }})</h1>
                <button mat-raised-button color="primary" (click)="showWherehouse()">{{'button.change_wherehouse' | translate}}</button>
            </mat-label>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="level==2">
            <mat-label>
                <h1 id="barcode">{{'label.warehousebarcode' | translate}}</h1>
            </mat-label>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>{{'label.barcode' | translate}}</mat-label>
                <input matInput #wrVal (keydown.enter)="checkWarehouseByBarCode(wrVal.value)"  [(ngModel)]="warehousebarcode">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <button mat-raised-button color="warn" (click)="updateshipmentitems(shipmentNo)" routerLink="/">{{'button.exit' | translate}}</button>
        </mat-grid-tile>
    </mat-grid-list>
</div>



