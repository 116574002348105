import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {Router} from '@angular/router'; 
import { MAT_DATE_FORMATS } from '@angular/material/core';

import {MatSnackBar} from '@angular/material/snack-bar';

import { ApiService } from '../api.service';


@Component({
  selector: 'app-add-shipment',
  templateUrl: './add-shipment.component.html',
  styleUrls: ['./add-shipment.component.css']
})
export class AddShipmentComponent implements OnInit {

  constructor(private route:Router, private snackBar: MatSnackBar, private fb: FormBuilder, private api: ApiService) { }
  profileForm = this.fb.group({
    shipmentno: [''],
    containerno: [''],
    supplierinvoiceno: [''],
    shipmentdate: [''],
    purchaseorder: ['']
  });
  ngOnInit(): void {
  }
  onSubmit(): void {
    // Process checkout data here
    this.api.addNewShipment(
      this.profileForm.value.shipmentno,
      this.profileForm.value.containerno,
      this.profileForm.value.supplierinvoiceno,
      this.changeFormat(this.profileForm.value.shipmentdate),
      this.profileForm.value.purchaseorder,
    ).subscribe(
      (response) => this.showMessage(response),
      (error) => console.log(error)
    )

  }
  showMessage(msg): void {
    this.snackBar.open(msg.msg, msg.status);
    this.route.navigate(['/shipments']);
  }
  changeFormat(time):string{
    var dd = String(time.getDate()).padStart(2, '0');
    var mm = String(time.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = time.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  }

}
