<mat-card>

  <mat-card-content>

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

      <h2>{{ 'auth.login' | translate }}</h2>

      <mat-error *ngIf="error">

        {{error}}

      </mat-error>

      <mat-form-field class="full-width-input">

        <input matInput placeholder="{{'auth.username' | translate}}" formControlName="username" required>

        <mat-error>

          Please provide a valid username

        </mat-error>

      </mat-form-field>

      <mat-form-field class="full-width-input">

        <input matInput type="password" placeholder="{{'auth.password' | translate}}" formControlName="password" required>

        <mat-error>

          Please provide a valid password

        </mat-error>

      </mat-form-field>

      <button [disabled]="loading" mat-raised-button color="primary">

        <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="30"></mat-progress-spinner>
        <span *ngIf="!loading">{{ 'auth.login' | translate }}</span>
      </button>

    </form>

  </mat-card-content>

</mat-card>