import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router'; 

import { ApiService } from '../api.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

export interface DialogData {
  shipmentNo: string;
}

@Component({
  selector: 'app-item-storing',
  templateUrl: './item-storing.component.html',
  styleUrls: ['./item-storing.component.css']
})
export class ItemStoringComponent implements OnInit {

  @ViewChild("txtVal") myInputField: ElementRef;
  @ViewChild("wrVal") myWrField: ElementRef;
  shipmentNo: string;
  itembarcode: string;
  warehousebarcode: string;
  warehouseName: string;
  itemcode: string;
  warehousecode: string;
  //level: number = 1;
  level: number = 2;
  spinnerDialogRef:any;
  constructor(private api: ApiService, public dialog: MatDialog, private snackBar: MatSnackBar, private route:Router, private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit(): void {
    this.openDialog();
  }
  public openAlertDialog(alertMsg) {
    this.confirmationDialogService.alert(alertMsg)
  }

  updateshipmentitems(shipmentid): void {
    console.log("shipmentid", shipmentid)
    this.api.updateshipmentitems(shipmentid).subscribe(
      (response) => this.showMessage(response),
      (error) => console.log(error)
    )
  }
  checkItemByBarCode(barcode): void {
    this.openSpinner();
    this.api.checkItemByBarCode(barcode).subscribe(
      (response) => this.showMessage(response),
      (error) => console.log(error)
    )
  }
  checkWarehouseByBarCode(barcode): void {
    this.openSpinner();
    this.api.checkWarehouseByBarCode(barcode).subscribe(
      (response) => this.showMessage(response),
      (error) => console.log(error)
    )
  }
  showMessage(msg): void {
    this.closeSpinner();
    if(msg.status=='error')
    {
      //this.snackBar.open(msg.msg, msg.status,{
      //  duration: 2000
      //});
      this.openAlertDialog(msg.msg);

    }
    else if(msg.results.itemcode){
      if(this.warehousebarcode !== undefined && this.warehousebarcode !== ''){
        setTimeout(() => this.myInputField.nativeElement.focus(),100);
        this.storingToWarehouse();
      }else{
        this.level = 2;
        setTimeout(() => this.myWrField.nativeElement.focus(),100);
      }
    }
    else{
      this.warehouseName = msg.results.warehouse;
      this.storingToWarehouse();
    }
  }
  
  showWherehouse(): void {
    this.level=2;
    this.warehousebarcode='';
    setTimeout(() => this.myWrField.nativeElement.focus(),100);
  }

  storingToWarehouse(): void {
    this.openSpinner();
    if(this.itembarcode!==undefined){
      this.api.storingToWarehouse(this.itembarcode,this.warehousebarcode,this.shipmentNo).subscribe(
        (response) => this.closeSpinner(),
        (error) => console.log(error)
      )  
    }else{
      this.closeSpinner()
    }
    this.itembarcode='';
    //this.warehousebarcode='';
    this.level=1;
    setTimeout(() => this.myInputField.nativeElement.focus(),100);
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(ShipmentStoringDialog, {
      width: '250px',
      data: {shipmentNo: this.shipmentNo},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result==undefined||result=='')
      {
        this.route.navigate(['/']);
      }
      else
      {
        this.api.checkShipment(result).subscribe(data=>{
          if(data['status']=='error')
          {
            //this.snackBar.open(data['msg'], data['status'],{
            //  duration: 2000
            //});
            this.openAlertDialog(data['msg']);

            this.route.navigate(['/']);
          }
          else
          {
            this.shipmentNo = result;
            //setTimeout(() => this.myInputField.nativeElement.focus(),150);
            setTimeout(() => this.myWrField.nativeElement.focus(),150);
          }
        });
      }
    });
  }

  openSpinner(): void {
   this.spinnerDialogRef = this.dialog.open(SpinnerStoringDialog, {
      width: '250px',
      disableClose: true
    });
  }

  closeSpinner(): void {
    this.spinnerDialogRef.close('Pizza!');
  }

}

@Component({
  selector: 'shipment-dialog',
  templateUrl: 'shipment-dialog.html',
})
export class ShipmentStoringDialog {

  constructor(
    public dialogRef: MatDialogRef<ShipmentStoringDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'spinner-dialog',
  templateUrl: 'spinner-dialog.html',
})
export class SpinnerStoringDialog {

  constructor() {}

}
