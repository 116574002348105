import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  getShipments() {
    return this.http.get(`${environment.apiUrl}/apinew/Shipment/getallshipments`);
  }
  addNewShipment(shipmentno,containerno,supplierinvoiceno,shipmentdate,purchaseorder) {
    return this.http.get(`${environment.apiUrl}/apinew/Shipment/createshipment?shipmentno=`+shipmentno+`&containerno=`+containerno+`&supplierinvoiceno=`+supplierinvoiceno+`&shipmentdate=`+shipmentdate+`&purchaseorder=`+purchaseorder);
  }
  getItems(shipmentid) {
    return this.http.get(`${environment.apiUrl}/apinew/Shipment/getshipmentitems?shipmentid=`+shipmentid);
  }
  updateshipmentitems(shipmentid) {
    return this.http.get(`${environment.apiUrl}/apinew/Shipment/updateshipmentitems?shipmentid=`+shipmentid);
  }
  
  checkItemByBarCode(barcode) {
    return this.http.get(`${environment.apiUrl}/apinew/CheckDetails/checkitem?barcode=`+barcode);
  }

  checkWarehouseByBarCode(barcode) {
    return this.http.get(`${environment.apiUrl}/apinew/CheckDetails/checkwarehouse?barcode=`+barcode);
  }

  addItemtoShipment(shipmentid,itemcode) {
    return this.http.get(`${environment.apiUrl}/apinew/Shipment/additemshipment?shipmentid=`+shipmentid+`&itemcode=`+itemcode);
  }

  storingToWarehouse(itembarcode,warehousebarcode,shipmentid) {
    return this.http.get(`${environment.apiUrl}/apinew/Shipment/updatewarehouse?shipmentno=`+shipmentid+`&itembarcode=`+itembarcode+`&warehousebarcode=`+warehousebarcode);
  }

  checkShipment(shipmentno){
    return this.http.get(`${environment.apiUrl}/apinew/CheckDetails/checkshipment?shipmentno=`+shipmentno);
  }

  createPurchaseReciept(shipmentno){
    return this.http.get(`${environment.apiUrl}/apinew/PurchaseReceipt/createpurchasereceipt?shipmentno=`+shipmentno);
  }
}
