import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ShipmentComponent } from './shipment/shipment.component';
import { ItemRecievingComponent } from './item-recieving/item-recieving.component';
import { ItemStoringComponent } from './item-storing/item-storing.component';
import { AddShipmentComponent } from './add-shipment/add-shipment.component';
import { AddItemsComponent } from './add-items/add-items.component';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: '', component: DashboardComponent , canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'shipments', component: ShipmentComponent, canActivate: [AuthGuard]  },
  { path: 'recieving', component: ItemRecievingComponent, canActivate: [AuthGuard]  },
  { path: 'storing', component: ItemStoringComponent, canActivate: [AuthGuard]  },
  { path: 'add-shipment', component: AddShipmentComponent, canActivate: [AuthGuard]  },
  { path: 'add-items/:shipmentId', component: AddItemsComponent, canActivate: [AuthGuard]  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
