<div *ngIf="shipmentNo" id="recieving">
    <h1>{{'label.items_in_shipment' | translate}} ({{ shipmentNo }})</h1>
    <mat-divider></mat-divider>
    <mat-grid-list cols="1" rowHeight="8:1">
        <mat-grid-tile>
            <mat-label>
                <h1 id="barcode">{{'label.barcode' | translate}}</h1>
            </mat-label>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>{{'label.barcode' | translate}}</mat-label>
                <input matInput #txtVal (keydown.enter)="checkItemByBarCode(txtVal.value)"  [(ngModel)]="barcode">
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <button mat-raised-button color="warn" routerLink="/">{{'button.exit' | translate}}</button>
        </mat-grid-tile>
    </mat-grid-list>
</div>