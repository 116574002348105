<div mat-dialog-content>
  <p>Enter the BarCode</p>
  <mat-form-field appearance="fill">
    <mat-label>Bar Code</mat-label>
    <input matInput [(ngModel)]="data.barcode">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="data.barcode" cdkFocusInitial>Add</button>
</div>
