<div class="example-button-container" routerLink="/shipments"  style="width: 23%;">
  <button mat-raised-button color="primary" >{{'button.back_to_shipment' | translate}}</button>
</div>

<h1>View Items - Shipping ID ({{ shipmentId }})</h1>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  'item_barcode','item_code','item_name','ware_barcode','warehouse_name'

  <!-- Name Column -->
  <ng-container matColumnDef="item_barcode">
    <th mat-header-cell *matHeaderCellDef> Item Barcode </th>
    <td mat-cell *matCellDef="let element"> {{element.item_barcode}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="item_code">
    <th mat-header-cell *matHeaderCellDef> Item Code </th>
    <td mat-cell *matCellDef="let element"> {{element.item_code}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="item_name">
    <th mat-header-cell *matHeaderCellDef> Item Name </th>
    <td mat-cell *matCellDef="let element"> {{element.item_name}} </td>
  </ng-container>

   <!-- Name Column -->
  <ng-container matColumnDef="ware_barcode">
    <th mat-header-cell *matHeaderCellDef> Warehouse Barcode </th>
    <td mat-cell *matCellDef="let element"> {{element.ware_barcode}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="warehouse_name">
    <th mat-header-cell *matHeaderCellDef> Warehouse Name </th>
    <td mat-cell *matCellDef="let element"> {{element.warehouse_name}} </td>
  </ng-container>

  <!-- Action Column -->
  <!-- <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let element" class="action-link"> 
        <button mat-raised-button color="accent" (click)="openItems(element)">Add Items</button>
      </td>
    </ng-container> -->

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>