import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-shipment',
  templateUrl: './shipment.component.html',
  styleUrls: ['./shipment.component.css']
})
export class ShipmentComponent implements OnInit {

  displayedColumns: string[] = ['container_no', 'purchaseorder', 'name', 'shipment_no', 'purchase_receipt_number', 'supplier', 'supplier_invoice', 'action'];
  dataSource: any = [];
  constructor(private api: ApiService, private route: Router, private confirmationDialogService: ConfirmationDialogService) {
    this.api.getShipments().subscribe(data => {
      this.dataSource = data['shipment'];
    });
  }

  ngOnInit(): void {

  }
  public openAlertDialog(alertMsg) {
    this.confirmationDialogService.alert(alertMsg)
  }
  public openSuccessDialog(alertMsg) {
    this.confirmationDialogService.success(alertMsg)
  }

  public openConfirmationDialog(shipment) {
    this.confirmationDialogService.confirm('Please confirm..', 'Create purchase receipt  ?')
    .then((confirmed) => {if(confirmed){this.createPurchaseReciept(shipment);} else {}})
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  openItems(shipment): void {
    console.log(shipment)
    this.route.navigate(['/add-items', shipment.shipment_no]);
  }
  handleCreatePurchaseReciept(shipment){
    this.openConfirmationDialog(shipment);
  }
  createPurchaseReciept(shipment): void{
    console.log("shipment", shipment)
    this.api.createPurchaseReciept(shipment.shipment_no).subscribe(data => {
      console.log(data);
      if(data["status"] === "error"){
        this.openAlertDialog(data["msg"]);
      }else{
        this.openSuccessDialog(data["msg"])
      }
    });
  }
  simpleAlert(){
    Swal.fire('Hello world!');
  }
   
  alertWithSuccess(){

    Swal.fire('Thank you...', 'You submitted succesfully!', 'success')
  }
   
  confirmBox(){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        console.log("yesss");
        Swal.fire(
          'Deleted!',
          'Your imaginary file has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("noooo");

        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }

}
