
<button id="home" mat-raised-button color="accent"  routerLink="/">{{'button.home' | translate}}</button>
<form [formGroup]="profileForm" class="form-container">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{'button.add_new_shipment' | translate}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{'table.shipment_no' | translate}}</mat-label>
            <input required formControlName="shipmentno" matInput placeholder="Ex. 345">
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{'table.container_no' | translate}}</mat-label>
            <input required formControlName="containerno" matInput placeholder="Ex. 435">
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>{{'table.purchase_order' | translate}}</mat-label>
          <input required formControlName="purchaseorder" matInput placeholder="Ex. 435">
      </mat-form-field>
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{'table.supplier_invoice' | translate}}</mat-label>
            <input required formControlName="supplierinvoiceno" matInput placeholder="Ex. 435">
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{'table.date' | translate}}</mat-label>
            <input required formControlName="shipmentdate" matInput [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        
      </mat-card-content>
      <mat-card-actions>
        <button (click)="onSubmit()" mat-raised-button color="primary">{{'button.save' | translate}}</button>
      </mat-card-actions>
    </mat-card>
  </form>