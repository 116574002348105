import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router'; 

import { ApiService } from '../api.service';

export interface DialogData {
  shipmentNo: string;
}

@Component({
  selector: 'app-item-recieving',
  templateUrl: './item-recieving.component.html',
  styleUrls: ['./item-recieving.component.css']
})
export class ItemRecievingComponent implements OnInit {
  @ViewChild("txtVal") myInputField: ElementRef;
  shipmentNo: string;
  barcode: string;
  spinnerDialogRef:any;
  constructor(private api: ApiService, public dialog: MatDialog, private snackBar: MatSnackBar, private route:Router) { }

  ngOnInit(): void {
    this.openDialog();
  }
  ngAfterViewInit() {
    }
  checkItemByBarCode(barcode): void {
    this.openSpinner();
    this.api.checkItemByBarCode(barcode).subscribe(
      (response) => this.showMessage(response),
      (error) => console.log(error)
    )
    this.barcode='';
  }
  showMessage(msg): void {
    if(msg.status=='error')
    {
      this.closeSpinner();
      this.snackBar.open(msg.msg, msg.status,{
        duration: 2000
      });
    }
    else{
      this.api.addItemtoShipment(this.shipmentNo,msg.results.itemcode).subscribe(
        (response) => this.closeSpinner(),
        (error) => console.log(error)
      )
    }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(ShipmentDialog, {
      width: '250px',
      data: {shipmentNo: this.shipmentNo}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result==undefined||result=='')
      {
        this.route.navigate(['/']);
      }
      else
      {
        this.api.checkShipment(result).subscribe(data=>{
          if(data['status']=='error')
          {
            this.snackBar.open(data['msg'], data['status'],{
              duration: 2000
            });
            this.route.navigate(['/']);
          }
          else
          {
            this.shipmentNo = result;
          
            setTimeout(() => this.myInputField.nativeElement.focus(),150);

          }
        });
      }
    });
  }

  openSpinner(): void {
   this.spinnerDialogRef = this.dialog.open(SpinnerDialog, {
      width: '250px',
      disableClose: true
    });
  }

  closeSpinner(): void {
    this.spinnerDialogRef.close('Pizza!');
  }

}

@Component({
  selector: 'shipment-dialog',
  templateUrl: 'shipment-dialog.html',
})
export class ShipmentDialog {

  constructor(
    public dialogRef: MatDialogRef<ShipmentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'spinner-dialog',
  templateUrl: 'spinner-dialog.html',
})
export class SpinnerDialog {

  constructor() {}

}
