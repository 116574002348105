<button mat-raised-button color="accent"  routerLink="/">{{'button.home' | translate}}</button>
<h1>{{'menu.shipment' | translate}}</h1>
<button mat-raised-button color="primary" class="pull-right"  routerLink="/add-shipment">{{'button.add_new_shipment' | translate}}</button>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="container_no">
      <th mat-header-cell *matHeaderCellDef>{{'table.container_no' | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.container_no}} </td>
    </ng-container>

    <ng-container matColumnDef="purchaseorder">
      <th mat-header-cell *matHeaderCellDef>{{'table.purchase_order_no' | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.purchaseorder}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{'table.name' | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="shipment_no">
      <th mat-header-cell *matHeaderCellDef>{{'table.shipment_no' | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.shipment_no}} </td>
    </ng-container>

    <ng-container matColumnDef="purchase_receipt_number">
      <th mat-header-cell *matHeaderCellDef>{{'table.purchase_receipt_number' | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.purchase_receipt_number}} </td>
    </ng-container>
    
    <!-- Symbol Column -->
    <ng-container matColumnDef="supplier">
      <th mat-header-cell *matHeaderCellDef>{{'table.supplier' | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.supplier}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="supplier_invoice">
        <th mat-header-cell *matHeaderCellDef>{{'table.supplier_invoice' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.supplier_invoice}} </td>
      </ng-container>

      <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>{{'table.action' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="action-link"> 
        <button mat-raised-button color="accent" (click)="openItems(element)">{{'button.view_items' | translate}}</button>
        <button mat-raised-button color="warn" (click)="handleCreatePurchaseReciept(element)">{{'button.create_purchase_reciept' | translate}}</button>
        <!--<button (click)="simpleAlert()">Simple Alert</button>
        <button (click)="alertWithSuccess()">Alert with Success</button>
        <button (click)="confirmBox()">Confirm Box</button>-->        
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  